import { ClickPromoBanner } from '~/plugins/integrations/tagManager/helpers/ClickPromoBanner';
import { Gtm } from '~/types/@nuxtjs/gtm';
import { TriggerClickPromoBannerParameters } from '~/types/integrations/tagManager/TagManagerIntegration';

export const triggerClickPromoBannerWrapper = ($gtm: Gtm) => {
  return (parameters: TriggerClickPromoBannerParameters) => {
    const clickPromoBanner = new ClickPromoBanner(parameters);
    $gtm.push(clickPromoBanner);
  };
};
