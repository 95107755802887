const { clientMutate } = require('../queries');
const { CUSTOM_QUERIES } = require('../../../../constants/customQueries.js');
const customerResetPasswordQuery = require('../../queries/password/customerResetPasswordQuery');
const handleCtApiError = require('../handleCtApiError');

/** @import {Customer, CustomQuery} from '~/utils/ct' */

/**
 * @param {Context} context
 * @param {string} tokenValue
 * @param {string} newPassword
 * @param {Maybe<CustomQuery>} customQuery
 * @returns {Customer}
 */
const customerResetPassword = async (context, tokenValue, newPassword, customQuery) => {
  const { locale, acceptLanguage } = context.config;
  const defaultVariables = tokenValue && newPassword
    ? {
      locale,
      acceptLanguage,
      tokenValue,
      newPassword,
    }
    : { acceptLanguage };
  const { customerResetPassword } = context.extendQuery(customQuery, {
    customerResetPassword: customerResetPasswordQuery[CUSTOM_QUERIES.PASSWORD.customerResetPassword]({
      variables: defaultVariables,
    }),
  });
  try {
    const response = await clientMutate(context, {
      mutation: customerResetPassword.query,
      variables: customerResetPassword.variables,
    });

    return response;
  } catch (error) {
    throw handleCtApiError(error);
  }
};

module.exports = customerResetPassword;
