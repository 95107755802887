import { computed, ref } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import type { Address } from '@vsf-enterprise/commercetools-types';
import { userShippingGetters, useShipping, useUser } from '@vsf-enterprise/commercetools';
import useShippingValidation from '~/composables/useShippingValidation';
import {
  useCheckout,
  useCheckoutAddress,
  useCookies,
  useShippingMethods,
  useUserShippingExtended,
  useShippingDetails,
  useCheckoutBilling,
  useShippingAddressType,
} from '~/composables';
import useCheckoutClientType from '~/composables/useCheckoutClientType';
import { ShippingAddressType } from '~/types/checkout/ShippingAddressType';
import { shippingAddressTypeCookie } from '~/constants/cookies';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import registerAddressErrors from '~/helpers/Checkout/registerAddressErrors/registerAddressErrors';
import { ValidationProvider } from '~/types/vee-validate';

// shared state
const saveAddressCheckbox = ref(false);

const useCheckoutShipping = () => {
  const { validateAddress } = useShippingValidation();
  const { loading: loadingShipping, error: shippingError } = useShipping();
  const { postEmail } = useCheckout();
  const { setClientTypeFromAddress } = useCheckoutClientType('Shipping');
  const { $cookies } = useVSFContext();
  const { shippingDetails, setShippingDetailsMutation } = useShippingDetails();
  const { billingDetails } = useCheckoutBilling();
  const useCheckoutAddressInstance = useCheckoutAddress({
    addressType: 'Shipping',
    scrollToTopOfForm: '#ShippingForm',
    addressDetails: shippingDetails,
  });
  const {
    displayNotification,
    resetNotifications,
    scrollToField,
    setScrollToField,
    removeUnnecessaryAddressProperties,
  } = useCheckoutAddressInstance;
  const {
    isShippingSelected,
    loading: loadingShippingMethods,
    error: errorShippingMethods,
  } = useShippingMethods();
  const {
    isAuthenticated,
  } = useUser();
  const {
    setDefaultAddress,
    shipping: userShipping,
    addAddress,
    updateAddress,
    error: userShippingError,
    currentCountryShippingAddresses,
  } = useUserShippingExtended();
  const { country } = useCookies();
  const { shippingAddressType, setShippingAddressTypeMutation } = useShippingAddressType();
  const anyShippingError = computed<Error | null | string>(() =>
    shippingError.value.load ||
    shippingError.value.save ||
    userShippingError.value.addAddress ||
    userShippingError.value.deleteAddress ||
    userShippingError.value.updateAddress ||
    userShippingError.value.load ||
    userShippingError.value.setDefaultAddress ||
    errorShippingMethods.selectShippingMethod ||
    errorShippingMethods.loadMethods,
  );

  const canMoveForward = computed(
    () =>
      !loadingShipping.value &&
      !loadingShippingMethods.value &&
      !anyShippingError.value &&
      isShippingSelected,
  );

  const handleShippingBeforeSubmit = async (
    isValid: Boolean,
    fields: Record<string, ValidationProvider> | undefined) => {
    resetNotifications();
    await setShippingFromBillingIfPossible();

    if (!isValid) {
      registerAddressErrors(fields);
    }
    await validateAddress(shippingDetails.value);
    postEmail(false);
  };

  const handleSaveShippingAddress = async () => {
    removeUnnecessaryAddressProperties();
    if (!shippingDetails.value.id) {
      const currentAddresses = currentCountryShippingAddresses.value;
      await addAddress({
        address: shippingDetails.value,
        customQuery: CUSTOM_QUERIES.UPDATE_MY_CUSTOMER_SHIPPING_ADDRESS,
      });
      return currentCountryShippingAddresses.value.filter(address => !currentAddresses.includes(address))[0];
    }
    await updateAddress({
      address: shippingDetails.value,
      customQuery: CUSTOM_QUERIES.UPDATE_MY_CUSTOMER_SHIPPING_ADDRESS,
    });
    return shippingDetails.value;
  };

  const handleShippingAfterSubmit = async () => {
    let savedAddress: Maybe<Partial<Address>> = null;
    if (isAuthenticated.value && saveAddressCheckbox.value) {
      savedAddress = await handleSaveShippingAddress();
    }
    if (savedAddress) {
      await setDefaultAddress({
        address: savedAddress,
        customQuery: CUSTOM_QUERIES.SET_MY_CUSTOMER_DEFAULT_SHIPPING_ADDRESS,
      });
    }
    if (!canMoveForward.value) {
      displayNotification();
      throw new Error('Error');
    }
  };

  const hasSavedShippingAddress = computed(() => {
    if (!isAuthenticated.value || !userShipping.value) {
      return false;
    }
    return Boolean(currentCountryShippingAddresses.value?.length);
  });

  const handleSetCurrentAddress = async (address: Maybe<typeof shippingDetails.value>) => {
    await setClientTypeFromAddress(address);
    await setShippingAddressType(ShippingAddressType.SAVED_ADDRESS);
    setShippingDetailsMutation({ ...(address) });
  };

  const selectDefaultAddress = async () => {
    const defaultAddress = userShippingGetters.getAddresses(userShipping.value, { isDefault: true });
    if (
      defaultAddress &&
      defaultAddress.length &&
      defaultAddress[0] &&
      defaultAddress[0]?.country === country?.toUpperCase()
    ) {
      await handleSetCurrentAddress(defaultAddress[0]);
    }
  };

  const changeShippingDetails = (field: keyof typeof shippingDetails.value,
    value: ValueOf<typeof shippingDetails.value>) => {
    setShippingDetailsMutation({
      ...shippingDetails.value,
      [field]: value,
    });
  };

  const setShippingFromBillingIfPossible = () => {
    if (shippingAddressType.value === ShippingAddressType.SAME_AS_BILLING) {
      if (!billingDetails.value) return;
      setShippingDetailsMutation({ ...billingDetails.value, id: undefined });
    }
  };

  const setShippingAddressType = async (newValue: ShippingAddressType) => {
    setShippingAddressTypeMutation(newValue);
    $cookies.set(shippingAddressTypeCookie, newValue);
    await setShippingFromBillingIfPossible();
  };

  const load = async () => {
    await setShippingFromBillingIfPossible();
  };

  const setSaveAddressCheckbox = (newValue: boolean) => {
    saveAddressCheckbox.value = newValue;
  };

  return {
    ...useCheckoutAddressInstance,
    handleShippingBeforeSubmit,
    handleShippingAfterSubmit,
    saveAddressCheckbox,
    hasSavedShippingAddress,
    shippingDetails,
    changeShippingDetails,
    handleSetCurrentAddress,
    anyShippingError,
    scrollToField,
    setScrollToField,
    setShippingFromBillingIfPossible,
    shippingAddressType,
    setShippingAddressType,
    selectDefaultAddress,
    setSaveAddressCheckbox,
    load,
  };
};

export default useCheckoutShipping;
