import type { InStoreMe, OrderQueryResult } from '@vsf-enterprise/commercetools-types';
import { useExpondoMiddleware } from '~/composables';
import type { ApiParams } from '~/utils/ct';
import { EXPONDO_MIDDLEWARE_ROUTES } from '~/constants/routes';

export function useUserOrderApi() {
  const { makeCommercetoolsRequest } = useExpondoMiddleware();

  const search = async ({ customQuery, ...searchParams }: ApiParams): Promise<OrderQueryResult | null> => {
    const response = await makeCommercetoolsRequest<{ me: InStoreMe }>({
      endpoint: EXPONDO_MIDDLEWARE_ROUTES.CT.GET_ORDERS,
      data: [searchParams, customQuery],
    });
    return response?.data?.me?.orders || null;
  };

  return {
    search,
  };
}
