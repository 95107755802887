import type { ApiParams, GetReviewParams, GetReviewResponse, Product, UseReviewData } from '~/utils/ct';
import { useExpondoMiddleware } from '~/composables';
import { useProductApi } from '~/utils/ct/composables/api/useProductApi';
import { GraphQLProductResponse } from '~/types/@vsf-enterprise/commercetools';
import { EXPONDO_MIDDLEWARE_ROUTES } from '~/constants/routes';

export function useReviewApi() {
  const { search: apiSearch } = useProductApi();
  const { makeCommercetoolsRequest } = useExpondoMiddleware();

  const search = async ({ customQuery, ...searchParams }: ApiParams<GetReviewParams>): Promise<UseReviewData> => {
    const response = await makeCommercetoolsRequest<GetReviewResponse>({
      endpoint: EXPONDO_MIDDLEWARE_ROUTES.CT.GET_REVIEW,
      data: [searchParams, customQuery],
    });
    const product = await apiSearch<GraphQLProductResponse<Product>>({ id: searchParams.productId, limit: 1 });
    const reviewStats = product?.data?.products?.results?.[0]?.reviewRatingStatistics || {};
    return {
      offset: response?.data?.offset ?? 0,
      total: response?.data?.total ?? 0,
      exists: response?.data?.exists ?? !!response?.data?.total,
      count: response?.data?.count ?? 0,
      limit: response?.data?.limit ?? 0,
      results: response?.data?.results ?? [],
      ...reviewStats,
    };
  };

  return {
    search,
  };
}
