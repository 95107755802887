import { Logger } from '@vue-storefront/core';
import { mapPaginationParams } from '~/utils/ct/composables/helpers/apiHelpers';
import type { ApiParams, ApiResponse } from '~/utils/ct';
import { useExpondoMiddleware } from '~/composables';
import { EXPONDO_MIDDLEWARE_ROUTES } from '~/constants/routes';

export function useProductApi() {
  const { makeCommercetoolsRequest } = useExpondoMiddleware();

  const search = async<T> ({ customQuery, ...searchParams }: ApiParams): Promise<ApiResponse<T> | null> => {
    const apiSearchParams = [
      {
        ...searchParams,
        ...mapPaginationParams(searchParams),
      },
      {
        customQuery,
      },
    ];
    try {
      const responseData = await makeCommercetoolsRequest<T>({
        endpoint: EXPONDO_MIDDLEWARE_ROUTES.CT.GET_PRODUCT,
        data: apiSearchParams,
      });
      return responseData;
    } catch (error: unknown) {
      Logger.error('Error during fetching product data: ', error);
      return null;
    }
  };

  return {
    search,
  };
}
