import type { Ref } from '@nuxtjs/composition-api';
import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext } from '@vue-storefront/core';
import type { Address } from '@vsf-enterprise/commercetools-types';
import { useI18n } from '~/composables/useI18n';
import { CheckoutClientType } from '~/types/checkout/formData/checkoutClientType';
import { AddressType } from '~/types/AddressType';
import useCheckoutAddressVat from '~/composables/useCheckoutAddressVat';
import { IS_COMPANY_DEFAULT_CLIENT_TYPE } from '~/config/shared/countryConfig';

const useCheckoutClientType = (step: AddressType) => {
  const { $cookies } = useVSFContext();
  const { setVatNumber } = useCheckoutAddressVat(step);
  const { countryCode } = useI18n();
  const clientCookieType = `ClientType-${step}`;
  const defaultClientType = (IS_COMPANY_DEFAULT_CLIENT_TYPE[countryCode.value] &&
   CheckoutClientType.COMPANY) || CheckoutClientType.PRIVATE;
  const defaultCookie = $cookies.get(clientCookieType) || defaultClientType;
  const clientType: Ref<CheckoutClientType> = sharedRef(defaultCookie, `useCheckoutClientType-${step}`);
  const isCompany = computed(() => clientType.value === CheckoutClientType.COMPANY);
  const setClientType = async (value: CheckoutClientType) => {
    $cookies.set(clientCookieType, value);
    clientType.value = value;
    !isCompany.value && await setVatNumber('');
  };
  const setClientTypeFromAddress = async (address: Maybe<Partial<Address>>) => {
    const clientType = address?.company
      ? CheckoutClientType.COMPANY
      : CheckoutClientType.PRIVATE;
    await setClientType(clientType);
  };

  return {
    clientType,
    isCompany,
    setClientType,
    setClientTypeFromAddress,
  };
};
export default useCheckoutClientType;
