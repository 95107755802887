import { Logger } from '@vue-storefront/core';
import { ref } from '@nuxtjs/composition-api';
import type { Category, Query } from '@vsf-enterprise/commercetools-types';
import type { SiblingCategory } from '~/types/product/ProductCategories';
import { useExpondoMiddleware, useI18n } from '~/composables';
import { ProductVariantExtended } from '~/types/product/ProductVariantExtended';
import { CUSTOM_QUERIES } from '~/constants/customQueries';
import { removeEmptyCategories } from '~/helpers/category/categoriesTree';
import { getBreadcrumbsFromProduct } from '~/helpers/category/getBreadcrumbsFromProduct';
import { EXPONDO_MIDDLEWARE_ROUTES } from '~/constants/routes';

export const useSiblingCategories = () => {
  const { languageAndCountry } = useI18n();
  const { makeCommercetoolsRequest } = useExpondoMiddleware();

  const siblingCategories = ref<Category[]>([]);

  const isSiblingCategory = (category: Category | SiblingCategory) => {
    return typeof category.slug === 'string' && typeof category.name === 'string';
  };

  const load = async (product: ProductVariantExtended) => {
    if (!product._breadcrumbs || !product._breadcrumbs[0]) {
      Logger.error('error.useSiblingCategories', 'Product breadcrumbs are missing.');
      return;
    }

    const breadcrumbs = getBreadcrumbsFromProduct(product);
    const lastCategorySlug = breadcrumbs?.at(-1)?.slug || {};
    const lastAncestorSlug = breadcrumbs?.at(-2)?.slug || {};

    if (!lastCategorySlug || !lastAncestorSlug) {
      return;
    }

    try {
      const categoryResponse = await makeCommercetoolsRequest<Query>({
        endpoint: EXPONDO_MIDDLEWARE_ROUTES.CT.GET_CATEGORY,
        data: [
          {
            slug: lastAncestorSlug,
            acceptLanguage: languageAndCountry.value,
          },
          {
            categories: CUSTOM_QUERIES.CATEGORIES.SIBLING,
          },
        ],
      });
      const siblingCategoriesResponse = categoryResponse?.data?.categories?.results?.[0]?.children;
      if (siblingCategoriesResponse?.length) {
        siblingCategories.value = siblingCategoriesResponse.filter((child: Category) => {
          const isNotEmpty = removeEmptyCategories([child], languageAndCountry.value).length > 0;
          return isNotEmpty && isSiblingCategory(child) && child.slug !== lastCategorySlug;
        });
      }
    } catch (err) {
      Logger.error('error.useSiblingCategories', String(err));
    }
  };

  return {
    siblingCategories,
    load,
  };
};
