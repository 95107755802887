const gql = require('graphql-tag');
const { CUSTOM_QUERIES } = require('../../../../constants/customQueries');
const DefaultCustomer = require('../fragments/defaultCustomer').fragment;

const customerResetPasswordQuery = {
  [CUSTOM_QUERIES.PASSWORD.customerResetPassword]: ({ variables }) => ({
    query: gql`
      ${DefaultCustomer}

      mutation customerResetPassword($tokenValue: String!, $newPassword: String!, $storeKey: KeyReferenceInput) {
        customerResetPassword(tokenValue: $tokenValue, newPassword: $newPassword, storeKey: $storeKey) {
          ...DefaultCustomer
        }
      }
    `,
    variables,
  }),
};

module.exports = customerResetPasswordQuery;
