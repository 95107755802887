import { CLICK_PROMO_BANNER, CLICK_PROMO_BANNER_DETAIL } from '~/constants/googleTagManager';
import { TriggerClickPromoBannerParameters } from '~/types/integrations/tagManager/TagManagerIntegration';

export class ClickPromoBanner {
  event = CLICK_PROMO_BANNER;
  event_info: {
    detail: string,
    campaign_name: string,
    page_category: string
  };

  constructor({ campaignName, pageCategory }: TriggerClickPromoBannerParameters) {
    this.event_info = {
      detail: CLICK_PROMO_BANNER_DETAIL,
      campaign_name: campaignName,
      page_category: pageCategory,
    };
  }
}
