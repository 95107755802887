











































































import Vue from 'vue';
import SfSelectOption from '@storefront-ui/vue/src/components/molecules/SfSelect/_internal/SfSelectOption.vue';
import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import VueI18n from 'vue-i18n';
import SelectOption from '~/types/checkout/formData/selectOption';

Vue.component('SfSelectOption', SfSelectOption);
export default defineComponent({
  name: 'Select',
  props: {
    /**
     * Select field label
     */
    label: {
      type: String as PropType<VueI18n.TranslateResult>,
      default: '',
    },
    /**
     * Required attribute
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Validate value of form select
     */
    valid: {
      type: Boolean,
      default: true,
    },
    /**
     * Disabled status of form select
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Error message value of form select. It will be appeared if `valid` is `true`.
     */
    errorMessage: {
      type: String,
      default: 'This field is not correct.',
    },
    /**
     * Value selected.
     */
    value: {
      type: String,
      default: '',
    },
    /**
     * Adds placeholder
     */
    placeholder: {
      type: String as PropType<VueI18n.TranslateResult>,
      default: '',
    },
    optionsArray: {
      type: Array as PropType<Array<SelectOption>>,
      default: () => {
        return [];
      },
    },
    name: {
      type: String,
      default: '',
    },
  },
  methods: {
    changeHandler(event) {
      this.$emit('input', event.target.value);
    },
  },
});
