























































































































































































































































































import { defineComponent, computed, onMounted, reactive } from '@nuxtjs/composition-api';
import type { ComputedRef } from '@nuxtjs/composition-api';
import SfSelectOption from '@storefront-ui/vue/src/components/molecules/SfSelect/_internal/SfSelectOption.vue';
import { SfModal } from '@storefront-ui/vue';
import PrimaryInput from '~/components/atoms/Inputs/PrimaryInput/PrimaryInput.vue';
import {
  SALESFORCE_WEBFORM_FIELD,
  SALESFORCE_WEBFORM_REASONS,
  SALESFORCE_FORM_INDICATOR,
  SALESFORCE_DYNAMIC_FIELDS,
  SALESFORCE_PRODUCT_NUMBER_MAX_LENGTH,
} from '~/constants/salesforce';
import Select from '~/components/atoms/Inputs/Select/Select.vue';
import CustomTextarea from '~/components/atoms/Inputs/CustomTextarea/CustomTextarea.vue';
import Button from '~/components/atoms/Buttons/Button/Button.vue';
import { useI18n } from '~/composables';
import {
  SalesforceDynamicFieldValue,
  SalesforceFormIndicatorValue,
  SalesforceFormFieldValue,
  SalesforceWebformReason,
  SalesforceWebformSubreason,
} from '~/types/salesforce';
import { DIGITS_ONLY } from '~/constants/regex';
import { salesforceWebformLocales } from '~/i18n';
import config from '~/config';
import { generateWebformEndpoint, getCurrentUrlWithRedirectionFlag } from '~/helpers/salesforce/salesforce';
import { removeProductSkuPrefix } from '~/helpers/product/removeProductSkuPrefix';
import isStringTrue from '~/helpers/dataStructure/isStringTrue';
import { isPreProduction } from '~/helpers/env/checkEnvironmentType';
import VatIdInput from '~/components/atoms/Inputs/VatIdInput/VatIdInput.vue';
import QuantitySelector from '~/components/atoms/Product/QuantitySelector.vue';

export default defineComponent({
  name: 'SaleforceWebform',
  components: {
    SfModal,
    PrimaryInput,
    Select,
    SfSelectOption,
    CustomTextarea,
    Button,
    VatIdInput,
    QuantitySelector,
  },
  props: {
    shouldPrefillFormForManual: {
      type: Boolean,
      default: false,
    },
    shouldPrefillFormForQuote: {
      type: Boolean,
      default: false,
    },
    sku: {
      type: String,
      default: '',
    },
  },
  setup (props, { emit }) {
    const { countryLabel, language, languageAndCountry } = useI18n();

    const salesforceWebformData = reactive<{
      selectedReasonIndicator: SalesforceFormIndicatorValue,
      selectedSubreasonIndicator: SalesforceFormIndicatorValue,
      selectedReason: SalesforceWebformReason | null,
      selectedSubreason: SalesforceWebformSubreason | null,
      locale: ValueOf<typeof salesforceWebformLocales>,
      description: string,
      contactName: string,
      companyName: string,
      customerEmail: string,
      subject: string,
      orderNumber: string,
      productNumber: string,
      invoiceNumber: string,
      vatId: string,
      quantity: number | null,
      phoneNumber: string,
      currentUrl: string}>(
        {
          selectedReasonIndicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_NONE,
          selectedSubreasonIndicator: SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_NONE,
          selectedReason: null,
          selectedSubreason: null,
          locale: salesforceWebformLocales[language.value],
          description: '',
          contactName: '',
          companyName: '',
          customerEmail: '',
          subject: '',
          orderNumber: '',
          productNumber: '',
          invoiceNumber: '',
          vatId: '',
          quantity: null,
          phoneNumber: '',
          currentUrl: '',
        },
      );

    const selectedReasonSubreasons: ComputedRef<SalesforceWebformSubreason[]> = computed(() => {
      return SALESFORCE_WEBFORM_REASONS.find(
        reason => reason.indicator === salesforceWebformData.selectedReasonIndicator,
      )?.subreasons || [];
    });

    const isDebugActive = computed(() => isStringTrue(process.env.salesforceDebugActive) || isPreProduction() ? 1 : 0);

    const updateSelectedReasonIndicator = (selectedValue: SalesforceFormFieldValue) => {
      salesforceWebformData.selectedReason = SALESFORCE_WEBFORM_REASONS.find(reason =>
        SALESFORCE_WEBFORM_FIELD[reason.indicator] === selectedValue,
      );
      salesforceWebformData.selectedReasonIndicator = salesforceWebformData.selectedReason
        ? salesforceWebformData.selectedReason?.indicator
        : '';

      updateSelectedSubreasonIndicator(null);
    };

    const updateSelectedSubreasonIndicator = (selectedValue: SalesforceFormFieldValue | null) => {
      salesforceWebformData.selectedSubreason = salesforceWebformData.selectedReason.subreasons?.find(subreason =>
        SALESFORCE_WEBFORM_FIELD[subreason.indicator] === selectedValue,
      );
      salesforceWebformData.selectedSubreasonIndicator = salesforceWebformData.selectedSubreason
        ? salesforceWebformData.selectedSubreason?.indicator
        : '';
    };

    const getFieldVisibilityComputed = (field: SalesforceDynamicFieldValue): ComputedRef<boolean> => {
      return computed(() => {
        return selectedReasonSubreasons.value.find(subreason =>
          subreason.indicator === salesforceWebformData.selectedSubreason?.indicator,
        )?.[field] || false;
      });
    };

    const onClose = () => {
      emit('close');
    };

    const shouldShowProductNumberField = getFieldVisibilityComputed(SALESFORCE_DYNAMIC_FIELDS.NEEDS_PRODUCT_NUMBER);
    const shouldShowInvoiceNumberField = getFieldVisibilityComputed(SALESFORCE_DYNAMIC_FIELDS.NEEDS_INVOICE_NUMBER);
    const shouldShowOrderNumberField = getFieldVisibilityComputed(SALESFORCE_DYNAMIC_FIELDS.NEEDS_ORDER_NUMBER);
    const shouldShowVatId = getFieldVisibilityComputed(SALESFORCE_DYNAMIC_FIELDS.NEEDS_VAT_ID);
    const shouldShowQuantity = getFieldVisibilityComputed(SALESFORCE_DYNAMIC_FIELDS.NEEDS_QUANTITY);
    const shouldShowPhoneNumber = getFieldVisibilityComputed(SALESFORCE_DYNAMIC_FIELDS.NEEDS_PHONE_NUMBER);

    const productNumber = removeProductSkuPrefix(props.sku);
    const preFillFormForManual = () => {
      updateSelectedReasonIndicator(
        SALESFORCE_WEBFORM_FIELD[SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_PRODUCT_CLAIM],
      );
      updateSelectedSubreasonIndicator(
        SALESFORCE_WEBFORM_FIELD[SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_MISSING_LANGUAGE_MANUAL],
      );
      salesforceWebformData.productNumber = productNumber;
    };

    const preFillFormForQuote = () => {
      updateSelectedReasonIndicator(
        SALESFORCE_WEBFORM_FIELD[SALESFORCE_FORM_INDICATOR.SALESFORCE_REASON_SALES],
      );
      updateSelectedSubreasonIndicator(
        SALESFORCE_WEBFORM_FIELD[SALESFORCE_FORM_INDICATOR.SALESFORCE_SUBREASON_DISCOUNT_REQUEST],
      );
      salesforceWebformData.productNumber = productNumber;
      salesforceWebformData.quantity = 1;
    };

    const validateProductNumber = () => {
      if (salesforceWebformData.productNumber) {
        salesforceWebformData.productNumber = salesforceWebformData.productNumber.replace(DIGITS_ONLY, '');
      }
    };

    const isProductNumberDisabled = computed(() => props.shouldPrefillFormForManual || props.shouldPrefillFormForQuote);

    onMounted(() => {
      salesforceWebformData.currentUrl = getCurrentUrlWithRedirectionFlag();
      if (props.shouldPrefillFormForManual) {
        preFillFormForManual();
      } else if (props.shouldPrefillFormForQuote) {
        preFillFormForQuote();
      }
    });

    const submissionUrl = generateWebformEndpoint({
      domain: config.SALESFORCE_WEBFORM.SUBMIT_URL,
      orgId: config.SALESFORCE_WEBFORM.ORGANISATION_ID,
    });

    return {
      submissionUrl,
      SALESFORCE_WEBFORM_REASONS,
      SALESFORCE_WEBFORM_FIELD,
      selectedReasonSubreasons,
      updateSelectedReasonIndicator,
      updateSelectedSubreasonIndicator,
      salesforceWebformData,
      countryLabel,
      shouldShowProductNumberField,
      shouldShowOrderNumberField,
      shouldShowInvoiceNumberField,
      shouldShowVatId,
      shouldShowQuantity,
      shouldShowPhoneNumber,
      isProductNumberDisabled,
      language,
      languageAndCountry,
      onClose,
      salesforceFieldName: config.SALESFORCE_WEBFORM.FIELD_ID,
      organisationId: config.SALESFORCE_WEBFORM.ORGANISATION_ID,
      isDebugActive,
      debugEmail: config.SALESFORCE_FIELD_VALUE.DEBUG_EMAIL,
      recordTypeValue: config.SALESFORCE_FIELD_VALUE.RECORD_TYPE,
      validateProductNumber,
      SALESFORCE_PRODUCT_NUMBER_MAX_LENGTH,
    };
  },
});
